.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
