.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .preloader_full-screen {
    height: 100vh;
  }
  
  .circle {
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
  
  .circle_green {
    background-color: #05c196;
    -webkit-transform: translateX(13px);
            transform: translateX(13px);
    -webkit-animation: animate-green 2s linear infinite;
            animation: animate-green 2s linear infinite;
  }
  
  .circle_red {
    background-color: #f01753;
    -webkit-transform: translateX(-13px);
            transform: translateX(-13px);
    -webkit-animation: animate-red 2s linear infinite;
            animation: animate-red 2s linear infinite;
  }
  
  @-webkit-keyframes animate-green {
    0% {
      background-color: #560e5c;
    }
  
    3% {
      background-color: #560e5c;
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
    }
    
    9% {
      background-color: #05c196;
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px);
    }
    
    14% {
      -webkit-transform: translateX(2px);
              transform: translateX(2px);
    }
  
    18% {
      -webkit-transform: translateX(-12px);
              transform: translateX(-12px);
    }
  
    21% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
  
    23% {
      -webkit-transform: translateX(-9px);
              transform: translateX(-9px);
    }
  
    24% {
      -webkit-transform: translateX(-7px);
              transform: translateX(-7px);
    }
  
    30% {
      -webkit-transform: translateX(-7px) scale(1);
              transform: translateX(-7px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    43% {
      z-index: -1;
      -webkit-transform: translateX(13px) scale(.6);
              transform: translateX(13px) scale(.6);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    56% {
      z-index: 1;
      -webkit-transform: translateX(33px) scale(1);
              transform: translateX(33px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
    
    69% {
      -webkit-transform: translateX(13px) scale(1.4);
              transform: translateX(13px) scale(1.4);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    82% {
      z-index: 1;
      background-color: #05c196;
      -webkit-transform: translateX(-7px) scale(1);
              transform: translateX(-7px) scale(1);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
  
    98% {
      background-color: #560e5c;
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
    }
  
    100% {
      background-color: #560e5c;
    }
  }
  
  @keyframes animate-green {
    0% {
      background-color: #560e5c;
    }
  
    3% {
      background-color: #560e5c;
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
    }
    
    9% {
      background-color: #05c196;
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px);
    }
    
    14% {
      -webkit-transform: translateX(2px);
              transform: translateX(2px);
    }
  
    18% {
      -webkit-transform: translateX(-12px);
              transform: translateX(-12px);
    }
  
    21% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
  
    23% {
      -webkit-transform: translateX(-9px);
              transform: translateX(-9px);
    }
  
    24% {
      -webkit-transform: translateX(-7px);
              transform: translateX(-7px);
    }
  
    30% {
      -webkit-transform: translateX(-7px) scale(1);
              transform: translateX(-7px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    43% {
      z-index: -1;
      -webkit-transform: translateX(13px) scale(.6);
              transform: translateX(13px) scale(.6);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    56% {
      z-index: 1;
      -webkit-transform: translateX(33px) scale(1);
              transform: translateX(33px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
    
    69% {
      -webkit-transform: translateX(13px) scale(1.4);
              transform: translateX(13px) scale(1.4);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    82% {
      z-index: 1;
      background-color: #05c196;
      -webkit-transform: translateX(-7px) scale(1);
              transform: translateX(-7px) scale(1);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
  
    98% {
      background-color: #560e5c;
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
    }
  
    100% {
      background-color: #560e5c;
    }
  }
  
  @-webkit-keyframes animate-red {
    0% {
      background-color: #560e5c;
    }
  
    3% {
      background-color: #560e5c;
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
    }
    
    9% {
      background-color: #f01753;
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
    
    14% {
      -webkit-transform: translateX(-2px);
              transform: translateX(-2px);
    }
  
    18% {
      -webkit-transform: translateX(12px);
              transform: translateX(12px);
    }
  
    21% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
  
    23% {
      -webkit-transform: translateX(9px);
              transform: translateX(9px);
    }
  
    24% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
    }
  
    30% {
      -webkit-transform: translateX(7px) scale(1);
              transform: translateX(7px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    43% {
      -webkit-transform: translateX(-13px) scale(1.4);
              transform: translateX(-13px) scale(1.4);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    56% {
      -webkit-transform: translateX(-33px) scale(1);
              transform: translateX(-33px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    69% {
      -webkit-transform: translateX(-13px) scale(.6);
              transform: translateX(-13px) scale(.6);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    82% {
      background-color: #f01753;
      -webkit-transform: translateX(7px) scale(1);
              transform: translateX(7px) scale(1);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
  
    98% {
      background-color: #560e5c;
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
    }
  
    100% {
      background-color: #560e5c;
    }
  }
  
  @keyframes animate-red {
    0% {
      background-color: #560e5c;
    }
  
    3% {
      background-color: #560e5c;
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
    }
    
    9% {
      background-color: #f01753;
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
    
    14% {
      -webkit-transform: translateX(-2px);
              transform: translateX(-2px);
    }
  
    18% {
      -webkit-transform: translateX(12px);
              transform: translateX(12px);
    }
  
    21% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
  
    23% {
      -webkit-transform: translateX(9px);
              transform: translateX(9px);
    }
  
    24% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
    }
  
    30% {
      -webkit-transform: translateX(7px) scale(1);
              transform: translateX(7px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    43% {
      -webkit-transform: translateX(-13px) scale(1.4);
              transform: translateX(-13px) scale(1.4);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    56% {
      -webkit-transform: translateX(-33px) scale(1);
              transform: translateX(-33px) scale(1);
      -webkit-animation-timing-function: cubic-bezier(.6, 0, 1, 1);
              animation-timing-function: cubic-bezier(.6, 0, 1, 1);
    }
  
    69% {
      -webkit-transform: translateX(-13px) scale(.6);
              transform: translateX(-13px) scale(.6);
      -webkit-animation-timing-function: cubic-bezier(0, 0, .4, 1);
              animation-timing-function: cubic-bezier(0, 0, .4, 1);
    }
  
    82% {
      background-color: #f01753;
      -webkit-transform: translateX(7px) scale(1);
              transform: translateX(7px) scale(1);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
  
    98% {
      background-color: #560e5c;
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
    }
  
    100% {
      background-color: #560e5c;
    }
  }
